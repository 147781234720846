@import '__importable.scss';
.popular {
	padding: 1.6rem;

	@media screen and (min-width: $media-md) {
		padding: 2.4rem;
	}
}

.container {
	padding: 1.6rem 1.6rem 0;

	ul li {
		list-style: disc;
		list-style-position: initial;
		margin-left: 1.6rem;
	}

	@media screen and (min-width: $media-md) {
		padding: 2.4rem 2.4rem 0;
	}
}