@import '__importable.scss';
.CartUsabilityContainer {
	background-color: $white;
	border: 1px solid $gray-2;
	padding: 0.8rem 1.6rem;
	border-radius: $radius-6;
	height: 100%;
}

.defaultContainer {
	padding: 2.4rem 1.6rem 0.8rem;
}
