@import '__importable.scss';
.container {
	@media screen and (min-width: $media-sm) {
	}
	@media screen and (min-width: $media-lg) {
	}
}

.title {
	> span:first-child {
		color: $blue;
	}

	> span:last-child {
		color: $pink-shade;
	}
}

.price {
	color: $gray-4;
}
