@import '__importable.scss';
.message {
	@include tooltipMessage;

	@media screen and (max-width: $media-sm) {
		width: 80%;
	}
}

.content {
	@include tooltipContent;
}
