@import '__importable.scss';
.main-image {
	position: relative;
	width: 100%;
	padding: 1.6rem;
	background-color: $gray-1;

	img {
		mix-blend-mode: multiply;
	}
}

.glasses-row {
	display: grid;
	grid-template-columns: 1fr 1fr;
	gap: 1.6rem;
	padding-block: 1.6rem;
}
