@import '__importable.scss';
.container {
	display: block;
	position: fixed;
	width: 100%;
	z-index: $zindex-10;
}

.header {
	position: relative;
	height: $header-height--mobile;
	padding: 0 1.6rem;
	border-bottom: 1px solid $gray-2;
	background-color: $white;

	@media screen and (min-width: $media-md) {
		padding: 0 3.2rem;
	}

	.grid {
		margin: 0 auto;
		height: 100%;
		display: flex;
		align-items: center;
		justify-content: space-between;
		position: relative;
		max-width: $max-width;

		&::after {
			content: none;
		}
	}

	.logoContainer {
		grid-template-columns: 1fr 1fr;
		display: grid;
		gap: 0.5rem;
		width: auto;

		@media screen and (min-width: $media-md) {
			grid-template-columns: 1fr;
		}
	}

	.leftNavContainer {
		width: 100%;
		display: flex;
		align-items: center;

		@media screen and (max-width: $media-md) {
			justify-content: center;
		}
	}

	.rightNavContainer {
		display: flex;
		align-items: center;
		flex-shrink: 0;
		height: 100%;
		position: relative;
		z-index: $zindex-2;

		@media screen and (min-width: $media-md) {
			padding-left: 0.8rem;
		}
	}
}
