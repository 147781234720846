@import '__importable.scss';
.overlay {
	background-color: rgba(0, 0, 0, 0.25);
	position: fixed;
	inset: 0;
}

.overlayWithoutBlur {
	position: fixed;
	inset: 0;

	@media screen and (min-width: $media-sm) {
		background-color: rgba(0, 0, 0, 0.25);
	}
}

.content {
	height: 75vh;
	width: 100%;

	position: fixed;
	bottom: 0;
	display: flex;
	flex-direction: column;

	overflow-y: scroll;
	background-color: $white;

	border-radius: $radius-6 $radius-6 0 0;

	@media screen and (min-width: $media-sm) {
		height: 100%;
		width: 490px;
		top: 0;
		// Fixes gap created on Spring Entry
		padding-right: 16px;
		right: -16px;
		border-radius: 0;
		border-left: 1px solid $gray-2;
	}
}

.fullHeight {
	height: calc(100% - $announcement-bar-height);
	width: calc(100% + 1.6rem);

	position: fixed;
	top: $announcement-bar-height;
	// Fixes gap created on Spring Entry
	padding-right: 1.6rem;
	right: -1.6rem;
	display: flex;
	flex-direction: column;

	border-radius: 0;

	overflow-y: scroll;
	background-color: $white;
	z-index: 20;

	@media screen and (min-width: $media-sm) {
		height: 100%;
		width: 490px;
		top: 0;

		border-left: 1px solid $gray-2;
	}
}

.gray {
	background: $gray-1;
}
