@import '__importable.scss';
.container {
	border-top: 1px solid $gray-2;
	width: 100%;
	position: sticky;
	z-index: 10;
	background: $white;

	&[data-page='cart'] {
		@media screen and (width >= $media-md-lg) {
			border-top: 0;
			background: transparent;
		}
	}
}

.footer {
	height: 100%;
	width: 100%;
	min-height: $footerHeight;
	display: grid;
	grid-template-columns: 1fr;
	grid-template-rows: repeat(4, auto);
	grid-template-areas:
		'nav'
		'social'
		'logo'
		'service';
	background-color: $white;
	padding: 3.2rem 1.6rem;
	max-width: $max-width;
	margin: 0 auto;

	&[data-page='cart'] {
		max-width: 100%;
	}

	h6 {
		margin-bottom: 1.6rem;
	}

	&__nav {
		grid-area: nav;
		display: grid;
		grid-template-columns: auto auto;
		gap: 1rem;

		&-item {
			li {
				a {
					color: $blue;
					font-size: 1.4rem;
					line-height: 1.6rem;

					&:hover {
						text-decoration: underline;
						opacity: 0.6;
					}
				}
			}
		}

		&-submenu {
			li {
				margin-bottom: 1.2rem;
			}
		}
	}

	&__social {
		grid-area: social;
		display: flex;
		flex-flow: column;
		justify-content: flex-start;
		align-items: center;
		margin: 0 auto;
		padding: 3.25rem 0;

		h3 {
			text-align: center;
		}

		&-list {
			margin: 0;
			display: flex;
			flex-flow: row wrap;
			justify-content: space-around;
			align-items: center;
			gap: 1.625rem;

			@media screen and (min-width: $media-md) {
				justify-content: flex-start;
			}

			li {
				flex-grow: 1;
				display: flex;
				justify-content: center;
			}

			a {
				border-radius: 50%;
				background: $blue;
				color: $white;
				height: 4rem;
				width: 4rem;
				font-size: 2rem;
				line-height: normal;
				display: flex;
				align-items: center;
				justify-content: center;

				&:hover {
					opacity: 0.6;
				}
			}

			svg {
				height: 2.2rem;
				width: 2.2rem;

				path {
					fill: white;
				}
			}
		}
	}

	&__service {
		grid-area: service;
		display: flex;
		flex-flow: row wrap;
		align-items: flex-end;

		&-item {
			margin: 0.4rem 0;
			text-align: center;
			flex: 1 0 calc(100% / 3);
		}

		&:last-of-type {
			flex-basis: 100%;
		}

		a,
		span {
			color: $gray-3;
			font-size: 1rem;
			font-weight: 300;

			&:hover {
				opacity: 0.75;
				text-decoration: underline;
			}
		}
	}

	&__logo {
		grid-area: logo;
		display: flex;
		align-items: center;
		justify-content: center;
		margin: 0 auto 3.2rem;
	}

	&__bottom-section {
		display: flex;
		flex-direction: column;
		gap: 3.2rem;
		padding-top: 3.2rem;

		@media screen and (min-width: $media-md-max) {
			padding-top: 0.4rem;
		}
	}

	&__disclaimer {
		display: flex;
		flex-direction: column;
		align-items: flex-start;
		width: 100%;

		div {
			max-width: 60.5rem !important;
			margin-inline: unset;
			padding-inline: unset !important;

			@media screen and (min-width: $media-xxl) {
				max-width: 90.5rem !important;
			}
		}
		p {
			font-size: 1.4rem;
			line-height: 200%;
		}
	}

	&__contact {
		display: flex;
		justify-content: center;
		align-items: center;
		gap: 0.8rem;

		@media screen and (min-width: $media-md-max) {
			justify-content: flex-start;
		}

		.line {
			width: 1px;
			height: 16px;
			background: $gray-3;
		}
	}

	@media screen and (min-width: $media-md-max) {
		grid-template-columns: 3fr auto;
		grid-template-rows: minmax(20rem, 1fr) max(7.5rem);
		grid-template-areas:
			'nav social'
			'service logo';
		grid-gap: 3.2rem;

		&__nav {
			grid-template-columns: 1fr 1fr 1fr;
			gap: 3.25rem;
		}

		&__social {
			max-width: 15.6rem;
			padding: 0;

			&-list {
				max-width: 80%;
			}
		}

		&__service {
			flex-wrap: nowrap;

			&-item {
				margin-right: 1.625rem;
				margin-bottom: 2.812rem;
				flex-basis: auto;
				text-align: left;

				a,
				span {
					font-size: 1.38rem;
				}

				&:last-of-type {
					flex-basis: auto;
				}
			}
		}

		&__logo {
			margin: 0 auto;
		}
	}

	@media screen and (min-width: $media-xl) {
		grid-gap: 1.6rem;

		&__nav {
			grid-template-columns: 1fr 1fr 1fr 1fr 1fr;

			&-item {
				a {
					font-size: revert;
					line-height: 2.2rem;
				}
			}

			&-submenu {
				li {
					margin-bottom: 2.2rem;
				}
			}
		}

		&__social {
			&-list {
				max-width: 65%;
			}
		}
	}

	@media screen and (min-width: $media-md-max) {
		padding: 3.2rem 1.6rem;
	}

	@media screen and (min-width: $media-xl) {
		padding: 3.2rem;
	}

	@media screen and (min-width: $media-xxl) {
		padding: 3.2rem 6.4rem;
	}

	&[data-page='cart'] {
		@media screen and (width >= $media-md-lg) {
			border-radius: $radius-6;
			border: 1px solid $gray-2;
		}

		@media screen and (min-width: $media-xl) {
			padding: 3.2rem;
		}

		@media screen and (min-width: $max-width) {
			padding: 3.2rem 6.4rem;
		}

		@media screen and (min-width: $media-md-max) {
			grid-template-columns: 3fr auto;
			grid-template-rows: minmax(20rem, 1fr) max(7.5rem);
			grid-template-areas:
				'nav social'
				'service logo';
			grid-gap: 3.2rem;

			.footer__nav {
				grid-template-columns: 1fr 1fr 1fr;
				gap: 3.25rem;
			}

			.footer__social {
				max-width: 15.6rem;
				padding: 0;

				&-list {
					max-width: 80%;
				}
			}

			.footer__service {
				flex-wrap: nowrap;

				&-item {
					margin-right: 0.5rem;
					margin-bottom: 2.812rem;
					flex-basis: auto;
					text-align: left;

					a,
					span {
						font-size: 1.38rem;
					}

					&:last-of-type {
						flex-basis: auto;
					}
				}
			}

			.footer__logo {
				margin: 0 0 0 auto;
			}
		}

		@media screen and (width >= $max-width) {
			.footer__nav {
				grid-template-columns: 1fr 1fr 1fr 1fr 1fr;

				&-item {
					a {
						font-size: revert;
						line-height: 2.2rem;
					}
				}

				&-submenu {
					li {
						margin-bottom: 2.2rem;
					}
				}
			}

			.footer__social {
				&-list {
					max-width: 65%;
				}
			}
		}
	}
	&__copyright {
		span {
			&:hover {
				opacity: 1;
				text-decoration: none;
			}
		}
	}
}
