@import '__importable.scss';
.sub-menu-group {
	position: fixed;
	top: 0;
	left: 100vw;
	width: 100vw;
	height: calc(100vh - $headerHeight);
	padding: 1.6rem;
	background: $gray-0;
	overflow-y: auto;
	overflow-x: hidden;
	transform: translateX(100%);
	transition: transform 300ms ease-out, z-index 250ms step-end;
	z-index: $zindex-3;

	.button-container {
		max-width: $max-width;
		margin: auto;
	}

	.mobile-back-btn {
		display: inline-flex;
		align-items: center;
		padding: 0 1.2rem 1.6rem 0;
		color: $gray-5;
	}

	@media screen and (min-width: $media-md) {
		position: fixed;
		top: $headerHeight;
		left: 0;
		width: 100%;
		height: unset;
		padding: 3.2rem;
		flex-grow: 1;
		background: $gray-0;
		transform: translate(0, -130%);
		transition: transform 200ms ease-out;
		z-index: $zindex-behind;
		box-shadow: 0 0.3rem 1rem 0 rgb(0 0 0 / 15%);
		pointer-events: all;

		.mobile-back-btn {
			display: none;
		}
	}
}

.is-visible {
	transform: translateX(0);
	transition: transform 300ms ease-out;

	@media screen and (min-width: $media-md-max) {
		transform: translate(0, 0);
		transition: transform 200ms ease-out;

	}
}