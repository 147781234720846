@import '__importable.scss';
.default {
    font-weight: 500;
    font-style: normal;
    font-size: 1.6rem;
    line-height: 150%;
    overflow-wrap: break-word;
    word-wrap: break-word;
    margin: 0;
	font-family: var(--poppins);
}