@import '__importable.scss';
.container {
	background-color: $gray-2;
	border: 1px solid $gray-2;
	margin: auto;
	padding: 1.6rem;
	border-radius: $radius-6;
	height: 100%;
}

.title {
	font-size: 1.6rem;
	font-weight: 500;
}