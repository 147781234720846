@import '__importable.scss';
.container {
	display: flex;
	&--row {
		flex-flow: row nowrap;
	}
	&--column {
		flex-flow: column nowrap;
	}
	&--alignCenter {
		align-items: center;
	}
	&--alignStart {
		align-items: flex-start;
	}
	&--alignEnd {
		align-items: flex-end;
	}
	&--alignBaseline {
		align-items: baseline;
	}
	&--justifyCenter {
		justify-content: center;
	}
	&--justifyStart {
		justify-content: flex-start;
	}
	&--justifyEnd {
		justify-content: flex-end;
	}
	&--justifyBetween {
		justify-content: space-between;
	}
	&--justifyAround {
		justify-content: space-around;
	}
	&--justifyEvenly {
		justify-content: space-evenly;
	}
}
