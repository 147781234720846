@import '__importable.scss';
.container {
	padding: 1.6rem;
	max-width: 34.3rem;
	overflow: revert !important;
    bottom: unset;
    top: 50%;
    left: 50%;
    translate: -50% -50%;
	border-radius: 1.6rem;

	@media screen and (min-width: $media-sm){
		max-width: 50rem;
	}
}
.copy {
	gap: 0.8rem;
	flex-direction: column;
	li {
		margin-left: 2rem;
		list-style-type: disc;
	}
}

.close-button {
	position: absolute;
	top: 1.2rem;
	right: 1rem;
}

.footer-buttons{
	padding-top: 1.6rem;
	gap: 0.8rem;
	flex-direction: column;

	[class*="Select_container"]{
		width: 100%;

		li{
			margin-left: auto;
		}
	}
	Button{
		width: 100%;
		gap: 0.8rem;
	}
	.country-button{
		color: $black;
	}
	.continue-button{
		height: 100%;
	}
	[class*="Select_fade"]{
		display: none;
	}

	@media screen and (min-width: $media-sm){
		flex-direction: row;
	}
}
