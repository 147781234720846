@import '__importable.scss';
.container {
	display: flex;
	flex-flow: column;
	background-color: $white;
	margin: -0.5rem 1.6rem 0 1.6rem;
	border: 1px solid $gray-2;
	box-shadow: 0 0.8rem 0.8rem rgba(0, 0, 0, 0.16);
	border-radius: $radius-4;
	width: 34.3rem;

	header {
		padding: 1.7rem 1.6rem;
		display: flex;
		align-content: center;
		justify-content: space-between;
		border-bottom: 1px solid $gray-2;

		p {
			align-self: center;
		}
	}

	ul {
		padding: 1rem 0;
		display: flex;
		flex-flow: column;

		li {
			button {
				border-radius: unset;
			}

			svg {
				width: 2rem;
				height: 2rem;
			}

			&:last-of-type {
				border-top: 1px solid $gray-2;

				a {
					margin-top: 0.5rem;
				}
			}

			@media (hover: hover) {
				&:hover {
					button {
						opacity: 1;
						color: $blue-tint;
						background-color: $gray-2;
						transition: all 0.3s ease;
						cursor: pointer;
					}
				}
			}
		}
	}

	@media screen and (min-width: $media-xsm) {
		margin-right: 0;
	}

	@media screen and (min-width: $media-md-max) {
		margin-right: 0;
		margin-left: 0;
	}
}

.nav-icon {
	@include navbarUserActionIcon;
}

.dropdownItem {
	outline: none;
}
