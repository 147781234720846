@import '__importable.scss';
.nav {
	position: fixed;
	top: $headerHeight;
	left: 0;
	width: 100vw;
	height: calc(100vh - $headerHeight);
	transform: translateX(-200vw);
	background: $gray-0;
	overflow-y: auto;

	// Mobile Nav shouldn't intercept pointer events when closed
	pointer-events: none;

	&[data-is-open='true'] {
		width: 200vw;
		transform: translateX(0);
		overflow: hidden;
		pointer-events: auto;

		&[data-has-open-sub-nav='true'] {
			transform: translateX(-100vw);
			transition: transform 250ms ease-out;
		}

		&[data-has-open-sub-nav='false'] {
			transform: translateX(0);
			transition: transform 250ms ease-out;
		}
	}
}

.nav-list {
	width: 100vw;
	height: calc(100vh - $headerHeight);
	padding: 0.8rem 1.6rem;
	transition: background-color 500ms linear;
}

@media screen and (min-width: $media-md) {
	.nav {
		position: static;
		top: unset;
		left: unset;
		width: 100%;
		height: unset;
		transform: unset;
		transition: unset;
		background: unset;
		overflow-y: unset;
		pointer-events: auto;
	}

	.nav-list {
		width: auto;
		padding: unset;
		display: flex;
		height: 5.8rem;
	}
}

.country-selector {
	width: calc(100vw - 3.6rem);
	[class*='Select_container'] {
		width: 100%;
	}
	[class*='Select_fade'] {
		display: none;
	}
	@media screen and (min-width: $media-md) {
		margin-left: auto;
		align-content: center;
		align-self: center;
		width: auto;
		position: initial;
		margin-right: 0.8rem;
		display: initial;
		[class*='Select_container'] {
			width: 100%;
		}
	}
	Button{
		color: $black;
	}
}