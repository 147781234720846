@import '__importable.scss';
.announcementBar {
    height: $announcement-bar-height;
	background-color: $blue-2;
	color: $gray-0;

    p {
        display: flex;
        height: 100%;
        align-items: center;
        justify-content: center;
        padding: 0 $gutter-site-mobile;
        white-space: nowrap;
		font-family: var(--platform);
		color: inherit;

        @media screen and (min-width: $media-md) {
            padding: 0 $gutter-site;
        }

        .emoji {
            margin: 0 0.5rem 0 0;
            font-size: 2rem;
            line-height: $announcement-bar-height;
        }

        .text {
            line-height: $announcement-bar-height;
            font-weight: 500;
            letter-spacing: 0.02em;
			font-family: var(--platform);
        }

        a {
            display: flex;
            align-items: center;
            justify-content: center;
            color: inherit;
			font-family: var(--platform);

            svg {
                height: 2rem;
                width: 2rem;
                margin-left: 0.8rem;
    
                path {
                    fill: inherit;
                }
            }

			&:hover {
				color: inherit;
				text-decoration: underline;
				opacity: 0.9;
			}
        }

        .influencerDiscountCode {
            color: #FFA07A;
            margin-inline: 0.1rem;
        }
    }
}

@-webkit-keyframes rainbow {
    0% {
        background-position: 0% 50%;
    }

    50% {
        background-position: 100% 50%;
    }

    100% {
        background-position: 0% 50%;
    }
}

@keyframes rainbow {
    0% {
        background-position: 0% 50%;
    }

    50% {
        background-position: 100% 50%;
    }

    100% {
        background-position: 0% 50%;
    }
}

.rainbow-animation {
    background: linear-gradient(270deg, #080593, #9700fd, #080593, #9700fd, #080593);
    background-size: 1200% 1200%;
    -webkit-animation: rainbow 10s ease;
    animation: rainbow 10s ease;
}

