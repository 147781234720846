@import '__importable.scss';
.container {
	border-radius: $radius-4;
	padding: 1.2rem 2.85rem;
	background-color: $beige;
	height: 12.8rem;
	width: 100%;
	overflow: hidden;
	position: relative;

	img {
		position: absolute;
		left: 0;
		top: -1.4rem;
		height: 16rem;
	}

	h4 {
		text-align: right;
		width: 20.5rem;
		font-size: 2rem;
	}

    @media screen and (min-width: $media-md) {
		padding: 3.2rem 1.6rem;
		height: 31.2rem;
		width: 50.4rem;

		img {
			position: absolute;
			left: -1.75rem;
			top: -4.6rem;
			height: 42rem;	
			width: 28rem;
		}

		h4 {
			width: 28.7rem;
			font-size: 2.8rem;
		}
    }
}
