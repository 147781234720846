@import '__importable.scss';
.price {
	color: $gray-4;
	font-size: 1.2rem;
	font-weight: 500;
	line-height: 1.56rem;
}

.compare-price {
	color: $gray-3;
	font-size: 0.8rem;
	font-weight: 500;
	line-height: 1.04rem;
	text-decoration: line-through;
	align-content: center;
}

.priceContainer {
	display: flex;
	gap: 0.8rem;
}