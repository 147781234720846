@import '__importable.scss';
.container {
	padding: 0;

	// @media screen and (min-width: $media-md-max) {
	// 	padding: 0.8rem 1.6rem;
	// }
	// @media screen and (min-width: $media-xl) {
	// 	padding: 0.8rem 1.6rem 0.8rem 3.2rem;
	// }
	// @media screen and (min-width: $media-xxl) {
	// 	padding: 0.8rem 1.6rem 0.8rem 6.4rem;
	// }
	// @media screen and (min-width: $max-width) {
	// 	padding: 0.8rem 1.6rem 0.8rem 12.8rem;
	// }
}

.containerInDrawer {
	background: $gray-1;
	// padding: 0 1.6rem;
}

.price {
	flex-wrap: wrap;
	margin-bottom: 1.6rem;

	.subtotalContainer {
		padding-top: 0.8rem;

		.subtotalAmount {
			margin: 0;
		}
	}
}

.upsellTitle {
	display: inline-block;
	min-height: 4rem;
	margin: 0;
	&--empty {
		margin: 2.4rem 0.8rem 1.6rem;
		@media screen and (min-width: $media-md) {
			margin: 0 0.8rem 1.6rem;
		}
	}
}

.checklist {
	padding: 0.8rem;
	background-color: $white;
	border-radius: $radius-6;
	@media screen and (min-width: $media-md) {
		padding: 0rem;
		margin-top: 2.4rem;
		border-radius: initial;
	}
}

.checklistPadded {
	padding: 0.8rem;
	background-color: $white;
	border-radius: $radius-6;
	margin-bottom: 1.6rem;
}

.topContainer {
	background-color: white;
	border: 1px solid $gray-2;
	margin: auto;
	padding: 1.6rem;
	border-radius: $radius-6;
	height: 100%;

	> .bundleTitle {
		margin-top: 0.8rem;
		font-size: 1.6rem !important;
		font-weight: 500 !important;
	}

	> .topFrameTitle {
		font-size: 2rem !important;
		font-family: var(--platform);
	}
}

.topContainerInDrawer {
	background-color: white;
	border: 1px solid $gray-2;
	margin: auto;
	padding: 1.6rem 1.6rem;
	border-radius: $radius-6;
	height: 100%;

	> p:first-child {
		margin-top: 0.8rem;
	}

	> .topFrameTitle {
		font-size: 2rem !important;
		font-family: var(--platform);
	}
}

.footer {
	padding: 0 0 11rem;

	@media screen and (min-width: $media-md) {
		padding: 0;
	}
}

.upsellContainer {
	margin-bottom: 1.6rem;
}

.cartUsabilityAccessories {
	margin: 0;
	font-size: 2rem;
}

.content {
	z-index: $zindex-3 !important;
}
