@import '__importable.scss';
.container {
	@include navbarUserActionIcon;

	>a {
		width: inherit;
		height: inherit;
		display: flex;
		align-items: center;
		justify-content: center;
	}

	@media screen and (hover: hover) {
		&:hover {
			svg path:nth-last-child(-n + 2) {
				animation: bounce-cart 0.5s ease;
			}
		}
	}
}

.count {
	background-color: $green-a11y;
	color: $gray-0;
	border-radius: 50%;
	font-family: var(--platform);
	font-size: 1.4rem;
	font-weight: 500;
	position: absolute;
	top: 6px;
	right: 6px;
	height: 16px;
	min-width: 16px;
	display: flex;
	align-items: center;
	justify-content: center;

	span {
		position: absolute;
		top: 50%;
		left: 50%;
		translate: -50% -50%;
	}
}

@keyframes bounce-cart {
	0% {
		transform: translateY(0);
	}

	20% {
		transform: translateY(0.1rem);
	}

	50% {
		transform: translateY(0rem);
	}

	80% {
		transform: translateY(0.1rem);
	}

	100% {
		transform: translateY(0);
	}
}