@import '__importable.scss';
.container {
	display: flex;
	align-items: center;
	justify-content: center;
	width: 100%;
	height: 100%;
	flex: 1;
}

.actionsContainer {
	position: absolute;
	top: 0;
	right: 0;
	z-index: 1;
}
.lozenge {
	margin-block: 0.4rem;
}

.dropzone {
	position: relative;
	width: 100%;
	height: 100%;
	min-height: 13.5rem;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	transition: all 0.3s ease;
}

.dragging {
	background-color: #f8f9fa;
}

.input {
	display: none;
}

.label {
	text-align: center;
	cursor: pointer;
}

.text {
	color: #666;
}
