@import '__importable.scss';
.container {
	padding: 0;
	position: relative;
	width: 100%;
	margin: 0 0 2.4rem 0;

	$inputPadding: 1.2rem 0.8rem;
	$inputWithIconPadding: 1.2rem 0.8rem 1.2rem 3.6rem;
	$inputWithIconPadding--right: 1.2rem 3.6rem 1.2rem 0.8rem;

	@mixin inputText() {
		font-size: 1.6rem;
		font-weight: 300;
		line-height: 2.4rem;
	}

	input {
		height: 4.8rem;
	}

	textarea {
		height: auto;
		resize: vertical;
	}

	input,
	textarea {
		@include hoverable-boxshadow($blue-tint);
		border-color: $gray-2;

		&[data-use-gray-bg] {
			@include hoverable-boxshadow($gray-3);
			border-color: $gray-2;
		}

		width: 100%;
		padding: $inputPadding;
		color: $gray-5;
		border-radius: $radius-2;
		@include inputText();
		cursor: text;

		&:focus {
			outline: none;
		}

		&::placeholder {
			color: transparent;
			@include inputText();
		}
	}

	label {
		position: absolute;
		top: 0;
		left: 0;
		margin: 0;
		padding: $inputPadding;
		color: $gray-4;
		transform: translateZ(0);
		transition: all 0.2s cubic-bezier(0, 0, 0.2, 1);
		pointer-events: none;
		@include inputText();
		.asterisk {
			color: $orange-3;
		}
	}

	// Styles when placeholder effect is not active
	input:focus,
	input:not(:placeholder-shown),
	textarea:focus,
	textarea:not(:placeholder-shown) {
		+ label,
		+ label .asterisk {
			color: transparent;
		}
	}

	&--placeholder-effect {
		input:focus,
		input:not(:placeholder-shown),
		textarea:focus,
		textarea:not(:placeholder-shown) {
			+ label {
				top: -0.7rem;
				left: 0.5rem;
				padding: 0.2rem 0.4rem;
				color: $gray-5;
				border-radius: $radius-2;
				background-color: white;
				font-size: 1.4rem;
				font-weight: 400;
				line-height: 1.4rem;
				.asterisk {
					color: $orange-3;
				}
			}
		}
	}

	&--has-error {
		input {
			@include hoverable-boxshadow($orange-shade, $orange-shade);
			border-color: $orange-shade;
		}
	}

	&--with-icon {
		input {
			// Fighting with LastPass
			background-repeat: no-repeat !important;
			background-size: unset !important;
			background-position: center left 0.8rem !important;
			padding: $inputWithIconPadding;

			&[type='email'] {
				background-image: url('https://cdn.shopify.com/s/files/1/1147/9910/files/ui-components__tiny-email.svg?v=1656035625') !important;
			}

			&[type='password'] {
				background-image: url('https://cdn.shopify.com/s/files/1/1147/9910/files/ui-components__tiny-padlock.svg?v=1656035625') !important;
			}

			&__right {
				padding: $inputWithIconPadding--right;
			}
		}

		label {
			padding: $inputWithIconPadding;
		}
	}

	&--disabled {
		background-color: $gray-1;
		border: none;
		cursor: not-allowed;
		label {
			color: $gray-5 !important;
		}

		input {
			cursor: not-allowed;
			background-color: $gray-1;
			border: none;
			pointer-events: none !important;
			&:hover {
				border: none !important;
				box-shadow: none !important;
			}
		}
	}

	&-error-message {
		display: inline-block;
		margin: 0.4rem 0 0 0.8rem;
		color: $orange-shade;
		font-family: var(--poppins);
		font-size: 1.4rem;
		font-weight: 400;
		line-height: 2.1rem;
		letter-spacing: 0em;

		&:empty {
			display: none;
		}
	}

	&[data-controlled-input] {
		input {
			scroll-margin-top: 4.8rem;
			color: $gray-4;
			font-weight: 400;
			cursor: pointer;
		}

		label {
			font-weight: 400;
		}
		textarea {
			resize: none;
			padding: 1.2rem;
		}		
	}
}

.asterisk {
	color: $orange-3;
}

.chevron {
	display: none;
	position: absolute;
	top: calc(4.8rem / 2);
	translate: 0 -50%;
	right: 1.2rem;
	border-radius: 50%;
	padding: 0.4rem;
	height: 2.4rem;
	width: 2.4rem;
	border: 1px solid transparent;

	&:active {
		border: 1px solid $gray-3;
	}

	&-open {
		color: $blue-tint !important;
		background-color: $gray-2;

		&:active {
			color: $blue-shade !important;
		}
	}

	@media (hover: hover) {
		&:hover {
			background-color: $gray-2;
		}
	}
	@media screen and (min-width: $media-sm) {
		display: block;
	}
}

.instruction-selected {
	input {
		color: $gray-4;
	}
}

.transparent-caret {
	caret-color: transparent;
}

.error-message {
	display: flex;
	position: absolute;
}

.input-active {
	input {
		background-color: $gray-2;
		border-color: $blue-tint;
	}
}
