@import '__importable.scss';
.caption {
	@media screen and (max-width: $media-sm) {
		margin: 1.6rem;
	}

	.icon {
		overflow: visible;
		margin: 1rem;
	}
}
