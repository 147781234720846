@import '__importable.scss';
.container {
	border: 1px solid $gray-2;
	padding-right: 0.8rem;
	width: 100%;
    border-radius: $radius-2;
    overflow: hidden;
    position: relative;
    display: flex;
    flex-direction: row;
    cursor: pointer;

	@media screen and (min-width: $media-md-max) {
		display: flex;
		flex-direction: column;
		padding-right: unset;
		border: none;
		width: 100%;
		height: 100%;
    }
}

.bgImage {
	width: 7.2rem;
	min-width: 7.2rem;
	cursor: pointer;

	@media screen and (min-width: $media-md-max) {
		position: absolute;
		overflow: hidden;
		width: 100%;
		height: 100%;

		&::before {
			content: '';
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
			background: linear-gradient(191deg, rgba(0, 0, 0, 0.00) 49.27%, rgba(0, 0, 0, 0.13) 70.64%, rgba(0, 0, 0, 0.50) 92.01%);
			pointer-events: none; /* Allows clicks to go through the pseudo-element */
		}

		img {
			width: 100%;
			height: 100%;
			object-fit: cover;
			border-radius: $radius-2;
		}
	}
}

.content {
	display: flex;
	flex-direction: unset;
	justify-content: space-between;
	width: 100%;
	height: 100%;
	padding: 0.8rem;
	align-items: center;

	@media screen and (min-width: $media-md-max) {
		display: flex;
		flex-direction: column;
		align-items: flex-start;
		color: white;
		position: absolute;
		bottom: 0rem;
		width: 100%;
		height: unset;
		padding: 1.6rem 1.6rem 2.4rem;

		h5,
		p {
			text-shadow: 1px 1px 0px rgba(0, 0, 0, 0.56), 0px 4px 4px rgba(0, 0, 0, 0.32);
		}
	}
}

.modal {
	padding: 1.6rem 1.6rem 2.4rem ;
}

.modalSubtitle {
	font-weight: 500;
}

.modalTrigger {
	display: flex;
	width: 100%;
}

.subtitle {
	font-size: 1rem;

	@media screen and (min-width: $media-md-max) {
		font-size: 1.4rem;
	}
}

.description {
	display: flex;
	flex-direction: column;
	align-items: flex-start;

	@media screen and (min-width: $media-md-max) {
		display: block;
	}
}
