@import '__importable.scss';
.autocomplete {
	gap: 0.8rem;

	&__collections {
		position: relative;
		border: none;
		margin: 0;
		background-color: transparent;

		font-size: 1.4rem;
		overflow-wrap: break-word;
		word-wrap: break-word;
		line-height: 150%;
		font-family: var(--poppins);
	}

	&__item {
		display: flex;
		white-space: pre;
		padding: 0.8rem 1.6rem 0.8rem 0.8rem;
		min-height: unset;
		line-height: 2rem;
		align-items: baseline;

		b, span {
			background-color: unset;
			line-height: 2rem;

			&:not(:first-of-type) {
				margin-left: 0.5ch;
			}

			&:empty {
				display: none;
			}
		}
	}

	&__redirect {
		@extend .autocomplete__item;
		color: $blue-2;
		font-weight: 700;
		display: flex;
		gap: 0.4rem;

		@media (hover: hover) {
			&:hover {
				a {
					text-decoration: underline;
				}
			}
		}
	}

	&__chevron {
		display: flex;
		align-items: center;
		justify-content: center;
		width: 1.6rem;
		height: 1.6rem;

		svg path {
			stroke: $blue-2;
			stroke-width: 1.25;
		}
	}
}