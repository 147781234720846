@import '__importable.scss';
$heartTransition: 100ms ease-in all;

.container {
	path {
		transition: $heartTransition;
		transform-origin: 50% 50%;
	}

	&.bounce {
		animation: 0.8s ease-in 0s beat;
	}

	&.active {
		fill: $orange-3;
		stroke: $orange-3;
		path {
			fill: $orange-3;
			stroke: $orange-3;
		}
	}
}

@media (hover: hover) {
	*:not(:disabled):not(:active):hover > .container {
		transform: scale(1.2);
	}
}
