@import '__importable.scss';
.container {
	display: flex;
	position: relative;
	overflow: hidden;
	gap: 0.8rem;
	border-radius: $radius-4;
	padding: 1.2rem;
	background-color: $gray-1;
	color: $gray-5;

	width: 100%;

	&--info {
		background-color: $blue-shade;
		color: $gray-0;
	}
	&--error {
		background-color: $orange-shade;
		color: $gray-0;
	}
	&--success {
		background-color: $green-shade;
		color: $gray-0;
	}

	@media screen and (min-width: $media-sm) {
		width: 48rem;
	}
}

.viewport {
	position: fixed;
	width: calc(100% - 3.2rem);
	bottom: 1.6rem;
	left: 50%;
	translate: -50% 0;
	z-index: $zindex-max-2;
	padding: 0;

	@media screen and (min-width: $media-sm) {
		width: auto;
		left: unset;
		translate: unset;
		right: 1.6rem;
	}
}

.close {
	background: $gray-0;

	svg path {
		stroke: $gray-5;
	}
}

.info {
	margin-top: 0.2rem;
}

.attention {
	margin-top: 0.2rem;
	height: 20px;
	width: 20px;
}

.check {
	margin-top: 0.2rem;
	border: 1.5px solid $gray-0;
	border-radius: 50%;
	height: 20px;
	width: 20px;
	padding: 3px 3px 3px 4px;
}

.timer {
	position: absolute;
	left: 0;
	bottom: 0;
	height: 0.4rem;
	background-color: rgba(255, 255, 255, 0.5);
}
