@import '__importable.scss';
.container {
	gap: 3.2rem;
	margin-left: 0.8rem;
	margin-bottom: 9.6rem;

	> ul {
		margin-top: 1.6rem;

		a {
			display: block;
			width: 100%;
			padding: 0.7rem 0.4rem 0.6rem;
		}
	}

	.list-item {
		margin-block: 0.8rem;
		height: 3.4rem;
		display: flex;
		align-items: center;
		position: relative;
	}

	.item-tag {
		position: absolute;
		height: 0.6rem;
		width: 0.6rem;
		left: -1rem;
		border-radius: 0.6rem;

		&[data-type='New'] {
			background: $green-2;
			color: $green-2;
		}
	}

	.item-tag-label {
		position: absolute;
		top: -0.4rem;
		left: 0.4rem;

		&[data-type='New'] {
			color: $green-2;

			&::after {
				content: '!';
			}
		}
	}

	@media screen and (min-width: $media-md) {
		display: flex;
		flex-direction: row;
		justify-content: center;
		height: auto;
		padding: 0 0 3.2rem 0;
		margin: 0;

		ul li button {
			margin: 1rem 1.6rem 0;
			padding: 0.6rem;
		}
	}
}

.tops-widget {
	position: relative;
	min-width: 34.3rem;
	height: 12.8rem;

	&.heading {
		color: $black;
		width: 100%;
		display: flex;
		flex-direction: column;
		align-items: flex-start;
	}

	img {
		object-fit: cover;
		border-radius: $radius-2;
		z-index: $zindex-behind;
	}

	button {
		position: absolute;
		right: 1.6rem;
	}

	@media screen and (min-width: $media-md) {
		@include hoverable();
		background-color: unset;
		overflow: hidden;

		flex-direction: column;
		min-width: unset;
		width: 22.4rem;
		height: 32rem;
		text-align: left;
		padding: 0;

		a {
			display: flex;
			flex-direction: column;
			padding: 1.6rem;
			overflow: hidden;

			@media (hover: hover) {
				opacity: 1;
			}
		}

		&.heading {
			color: $black;
			width: 100%;
			display: flex;
			flex-direction: column;
			align-items: flex-start;
		}

		img {
			top: 0;
			left: 0;
			height: 100%;
			width: 100%;
			object-fit: cover;
			z-index: $zindex-behind;
		}

		button {
			position: static;
			width: 100%;
			margin-top: 100%;
		}

		@media (hover: hover) {
			&:hover {
				img {
					transform: inherit;
					scale: 1.025;
				}

				button {
					&:not([disabled]) {
						background-color: $gray-0;
						border-color: $gray-3;
						box-shadow: 1px -1px 0px 1px $gray-3 inset;

						span {
							color: inherit;

							&[data-text-animate] {
								transform: translate3d(2px, -2px, 0);
								transition: all cubic-bezier(0.19, 1, 0.22, 1) 200ms;
							}
						}
					}
				}
			}

			&:active {
				background-color: unset;
				opacity: 1;

				&:not([disabled]) {
					button {
						box-shadow: none;
					}
				}
			}
		}
	}
}

.lenses-container {
	display: flex;
	flex-direction: column-reverse;
	gap: 0;

	@media screen and (min-width: $media-md) {
		flex-direction: row;
		gap: 6.4rem;
	}
}
