@import '__importable.scss';
.container {
	max-width: 100%;
}

.buttonsContainer {
	margin: 1.6rem 0;

	> a {
		flex: 2;
	}
}

.footer {
	text-align: center;
	> p {
		color: $gray-4;
	}
}
