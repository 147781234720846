@import '__importable.scss';
.pd-container {
	background-color: $lightblue-tint;
	padding: 1.2rem;
	border-radius: 0.8rem;
	width: 100%;
}

.close-icon {
	&:hover {
		background-color: unset;
		transition: unset;
		cursor: pointer;
	}
}

.success {
	color: $green-4;
	padding: 1.6rem;
}
