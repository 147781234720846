@import '__importable.scss';
.navigation-container {
	padding: 0 1.6rem 1.6rem 0;
	@media screen and (min-width: $media-md) {
		padding-bottom: 2.4rem;
	}
}

.button-prev,
.button-next {
	@include navigationButton;
	position: initial;
	width: 3.2rem;
	height: 3.2rem;
	z-index: $zindex-1;

	@media (max-width: $media-md) {
		display: flex;
	}
}

.disabled {
	background-color: $gray-2;
	color: $gray-3;
	cursor: unset;
}

.loading {
	position: relative;
	height: 5rem;
	width: auto;
}

.recommendations {
	border-top: 1px solid $gray-2;
	border-bottom: 1px solid $gray-2;
	background: $gray-0;
	width: 100vw;
	border-radius: $radius-6;
	
	@media screen and (min-width: $media-xsm) {
		margin: 1.6rem 0;
	}

	@media screen and (min-width: $media-md-lg) {
		border: 1px solid $gray-2;
		width: 100%;
		margin: 0;
	}
	
}

.recommendations-minicart {
	border-top: 1px solid $gray-2;
	border-bottom: 1px solid $gray-2;
	background: $gray-0;
	margin: 1.6rem 0;
	border-radius: $radius-6;
}

.recommendation-card {
	background-color: $gray-1;
	[class*="Button_priceContainer"] {
		gap: 0.8rem;
	}
	[class*="Button_price"] {
		&:first-child {
			font-size: 1.2rem;
		}
		font-size: 0.8rem;
		align-content: space-evenly;
	}
	@media screen and (max-width: $media-sm) {
		[class*="Button_button"] {
			padding-left: 0.2rem !important;
		}

		[class*="Button_priceContainer"] {
			gap: 0.4rem;
		}
	}	
	
}
