@import '__importable.scss';
.absolute {
	position: absolute !important;
	top: 1.6rem;
	right: 1.6rem;
	z-index: $zindex-2;
}

.bounce {
	animation: 0.8s ease-in 0s beat;
}
