@import '__importable.scss';
.container {
	position: sticky;
	top: 0;
	background-color: $white;
	border-bottom: 1px solid $gray-2;
	z-index: $zindex-9;

	&--respectViewport {
		top: 0;
	}

	&--respectAnnouncementBar {
		top: $announcement-bar-height;
	}

	&--respectSiteHeader {
		top: $headerHeight;
	}
}

.grid {
	display: grid;
	grid-template-columns: 1fr 1fr 1fr;
	gap: 0.8rem;
	place-items: center;
	max-width: $max-width;
	margin-left: auto;
	margin-right: auto;
	height: 6rem;
	padding: 0.8rem;

	&--no-drawer {
		@media screen and (min-width: $media-md) {
			padding: 0.8rem 1.6rem;
		}
		@media screen and (min-width: $media-xl) {
			padding: 0.8rem 3.2rem;
		}
		@media screen and (min-width: $media-xxl) {
			padding: 0.8rem 6.4rem;
		}
		@media screen and (min-width: $max-width) {
			padding: 0.8rem 12.8rem;
		}
	}
}

.close-button {
	@include navigationButton;
	height: 2.6rem;
	width: 2.6rem;
	position: relative;
	justify-self: flex-start;

	@media (max-width: $media-md) {
		display: flex;
	}
}
