@import '__importable.scss';
@import '../../../styles/variables';

.container {
	display: flex;
	flex-flow: column nowrap;
	justify-content: center;
	align-items: center;
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	width: 100%;
	height: 100%;
	background: transparent;
	animation: fadeIn $transition-3;
	z-index: $zindex-2;

	> div {
		margin-bottom: 1rem;
	}

	.phrase {
		margin-top: 1.6rem;
	}
}

.jumping-dots {
	display: grid;
	height: 0.8rem;
	grid-gap: 0;
	grid-template-columns: repeat(3, 1.6rem);
	justify-items: center;

	.outer {
		animation: jumping-dots 1s infinite cubic-bezier(0.175, 0.185, 0.42, 1.275) backwards;
		border-radius: 100%;
		height: 0.8rem;
		width: 0.8rem;

		&:nth-child(1) {
			animation-delay: 0.1s;
		}
		&:nth-child(2) {
			animation-delay: 0.2s;
		}
		&:nth-child(3) {
			animation-delay: 0.3s;
		}
	}

	.inner {
		background: $orange-3;
		height: inherit;
		width: inherit;
		border-radius: inherit;
		animation: color-change 5s infinite ease backwards;
	}

	.white {
		@extend .inner;
		background: white !important;
	}
}

.small {
	@extend .jumping-dots;
	height: 0.5rem;
	display: grid;
	grid-template-columns: repeat(3, 0.7rem);

	.outer {
		@extend .outer;
		height: 0.5rem;
		width: 0.5rem;
	}
}

@keyframes jumping-dots {
	0%,
	100% {
		transform: translateY(0);
		transition-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1.275);
	}
	20%,
	30%,
	70%,
	80% {
		transform: translateY(1rem);
	}
	40%,
	50%,
	60% {
		transform: translateY(1rem);
	}
}

@keyframes color-change {
	15% {
		background-color: $blue-1;
	}
	35% {
		background-color: $green-1;
	}
	55% {
		background-color: $pink-2;
	}
	75% {
		background-color: $lightblue-3;
	}
	95% {
		background-color: $orange-1;
	}
}
