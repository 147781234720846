@import '__importable.scss';
.overlay {
	background: rgba(0 0 0 / 0.25);
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	display: grid;
	place-items: center;
	overflow-y: auto;
	z-index: $zindex-max;
}

.content {
	height: auto;
	max-height: 75vh;
	width: 100%;

	position: fixed;
	bottom: 0;

	overflow-y: scroll;
	background-color: $white;
	padding: 3rem 1.6rem 0;

	border-radius: $radius-6 $radius-6 0 0;
	z-index: $zindex-2;

	-ms-overflow-style: none;
	scrollbar-width: none;

	&::-webkit-scrollbar {
		display: none;
	}

	@media screen and (min-width: $media-sm) {
		min-width: 35rem;
		max-width: calc($max-width / 2);
		height: auto;
		border-radius: $radius-6;
		bottom: unset;
		top: 50%;
		left: 50%;
		translate: -50% -50%;
	}

	@media screen and (min-width: $media-sm) {
		padding: 3rem 2rem 2rem;
	}
}

.contentCenter {
	width: calc(100% - 3.2rem);
	max-width: calc($max-width / 2);
	height: auto;
	border-radius: $radius-6;
	bottom: unset;
	top: 50%;
	left: 50%;
	translate: -50% -50%;
	z-index: $zindex-2;
	padding: 3rem 2rem 2rem;

	-ms-overflow-style: none;
	scrollbar-width: none;

	&::-webkit-scrollbar {
		display: none;
	}
}

.contentWithoutPadding {
	@extend .content;
	padding: 0;
}

.small {
	@extend .contentCenter;

	@media screen and (min-width: $media-md) {
		max-width: calc($max-width / 3);
	}
}

.hideOverflow {
	overflow: hidden;
}

.close-container {
    position: sticky;
    top: 0;
    right: 0;
    z-index: $zindex-10;
}

.close {
    position: absolute;
    z-index: $zindex-10;
    border-radius: $radius-round;
    top: 1rem;
    right: 1rem;
	background-color: $white;

    &:focus-visible {
        box-shadow: 0 0 0 2px $pink-2;
    }
}
