@import '__importable.scss';
// Height controlled by InfiniteHits.module.scss class ".tops"
// Fixes stuttering due to variable height miscalculations of BuildFlowCards when rendering VirtuosoGrid
// Example: [class*='BuildFlowCard_container'] { min-height: 205px; }
.container {
	&--bundle{
		height: auto !important;
	}
	.button {
		justify-content: space-between;
		padding: 0.95rem, 0.8rem, 0.95rem, 0.8rem;
		@include button-hover-effect();

		&Clicked {
			@include button-hover-effect($blue-1, $blue-1, $blue-2);
		}

		.price {
			color: $gray-4;
			font-size: 1.2rem;

			&Clicked {
				color: inherit;
			}
			&Compare {
				color: $red-1;
			}
		}

		.priceContainer {
			display: flex;
			gap: 0.4rem;
			align-items: center;

			.comparePrice {
				color: $gray-3;
				font-size: 0.8rem;
				align-content: center;
				text-decoration: line-through;
			}
		}
	}

	&Selected {
		border-width: 0.1rem;
		transition: border 200ms ease;
		border-color: $blue-tint;
	}
}
