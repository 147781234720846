@import '__importable.scss';
.menu-group {
	padding: 0.8rem;
	margin-block: 0.8rem;

	&.margin-left-auto {
		margin-left: auto;
	}
}

.menu-group-text {
	width: 100%;
	color: $blue;
	cursor: pointer;
}

.has-sub-nav {
	cursor: default;
}

.indicator-container {
	>svg:first-of-type {
		height: 100%;
		width: 20px;
	}

	>svg:nth-of-type(2) {
		display: none;
	}
}

.divider {
	height: 0.2rem;
	width: calc(100% + 0.4rem);
	margin-left: -0.2rem;
	background-color: $gray-2;
}

// Progressively hide links as window width shrinks
@media screen and (min-width: $media-md) and (max-width: rem-calc(925px)) {
	.menu-group[data-handle='refer-a-friend'] {
		display: none;
	}
}

@media screen and (min-width: $media-md) and (max-width: rem-calc(929px)) {
	.menu-group[data-handle='about'] {
		display: none;
	}
}

@media screen and (min-width: $media-md) {
	.menu-group {
		padding: 0;
		margin-block: unset;
		position: relative;

		>a {
			display: flex;
			justify-content: flex-start;
			align-items: center;
			padding: 0 0.8rem;
			height: 100%;
			width: auto;
			color: $gray-5;
		}

		>li[data-is-sub-nav-open='false'] {

			//Sub-Nav State
			>div {
				transform: translateY(-130%);
				transition: transform 200ms ease-out;
			}
		}

		>li[data-is-sub-nav-open='true'],
		>li:focus-within {

			// Top-Level Nav State
			>button {
				box-shadow: inset 0 -0.4rem 0 0 $blue-1;
				transition: box-shadow 300ms ease-out;

				svg {
					transform: rotate(180deg);
					transition: transform 150ms ease-out;
				}
			}

			// Sub-Nav State
			>div {
				box-shadow: 0 0.3rem 1rem 0 rgb(0 0 0 / 15%);
				transform: translateY(0);
				z-index: $zindex-behind;
				transition: transform 250ms ease-out;
			}
		}

		&[data-handle='rewards'] {
			display: none;
		}


		&::after {
			content: '';
			background-color: $blue-1;
			position: absolute;
			left: 0;
			bottom: 0;
			width: 100%;
			height: 0;
			opacity: 0;
			transition: opacity 200ms ease-in, height 200ms ease-in;
			z-index: $zindex-1;
		}

		&:not([data-is-sub-nav-open]):hover {
			&::after {
				opacity: 1;
				height: 4px;
				transition: opacity 200ms ease-in, height 200ms ease-in;
			}
		}
	}

	.indicator-container {
		>svg:nth-of-type(1) {
			display: none;
		}

		>svg:nth-of-type(2) {
			display: block;
		}
	}

	.divider {
		position: relative;
		top: 0.1rem;
		width: 0.2rem;
		height: 1.6rem;
		margin: 0.8rem;
		align-self: center;
		background-color: $gray-2;

		&.mobile-only {
			display: none;
		}
	}
}