@import '__importable.scss';
@import '@styles/colors';
@import '@styles/variables';

.icon {
	position: relative;
	display: flex;
	align-items: center;
	justify-content: center;
	border-radius: $radius-round;
	border: 2px solid transparent;
	transition: $transition-2 ease-out background-color, $transition-2 ease-in border-color;

	&:hover {
		background-color: $gray-2;
		transition: $transition-2 ease-out background-color;
		cursor: pointer;

		path {
			stroke: $gray-5;
			transition: $transition-2 ease-out stroke;
		}
	}

	&:active {
		border-color: $gray-3;
		transition: $transition-2 ease-in border-color;
		cursor: pointer;

		path {
			stroke: $gray-5;
			transition: $transition-2 ease-in stroke;
		}
	}
}

.noBorder {
	border: none;
}
