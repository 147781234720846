@import '__importable.scss';
.container {
	position: relative;
	flex-grow: 1;

	> *:first-child {
		position: absolute;

		top: 50%;
		transform: translateY(-50%);
		left: 1.6rem;
	}

	> *:last-child {
		position: absolute;

		top: 50%;
		transform: translateY(-50%);
		right: 1.6rem;
	}

	.searchInput {
		height: 4rem;
		border-radius: 2rem; // can we use a variable here?
		width: 100%;
		padding-left: 4.8rem;
		background-color: $gray-1;
		border: 1px solid $gray-2;
		caret-color: $blue;

		font-family: inherit;
		font-style: normal;
		font-weight: 400;
		font-size: 1.6rem;
		line-height: 2.1rem;

		&:focus,
		&:focus-visible {
			background-color: $gray-2;
			border-color: $gray-3;
			outline: none;
		}

		&::-webkit-search-decoration,
		&::-webkit-search-cancel-button,
		&::-webkit-search-results-button,
		&::-webkit-search-results-decoration {
			display: none;
		}

		@media screen and (min-width: $media-sm) {
			&:hover {
				background-color: $gray-2;
			}
		}
	}

	&.active {
		width: 3.2rem;

		> *:first-child {
			transform: translate(-50%, -50%);
			left: 50%;
		}

		.searchInput {
			width: 3.2rem;
			padding: 0;
			text-indent: 100%;
		}

		> *:last-child {
			display: none;
		}
	}
}

.hidden {
	display: none;
}
