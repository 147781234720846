@import '__importable.scss';
.userActionNav {
	position: relative;
	display: flex;
	align-items: center;
	justify-content: space-between;
	gap: 0.8rem;
	margin-right: 0;
	margin-left: 0;

	button {
		position: relative;
		min-height: 2rem;
		min-width: 2rem;
	}
}

.nav-icon {
	@include navbarUserActionIcon;

	@media screen and (min-width: $media-sm) {
		&:hover {
			&.minicart-icon svg path:nth-last-child(-n + 2) {
				transform: translateY(0.1rem);
			}
		}
	}
}

.cartCount {
	background-color: $green-a11y;
	color: $gray-0;
	border-radius: 50%;
	font-family: var(--platform);
	font-size: 1.4rem;
	font-weight: 500;
	position: absolute;
	top: 6px;
	right: 6px;
	height: 16px;
	min-width: 16px;
	display: flex;
	align-items: center;
	justify-content: center;

	span {
		position: absolute;
		top: 50%;
		left: 50%;
		translate: -50% -50%;
	}
}