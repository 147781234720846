@import '__importable.scss';
.ul,
.ol {
	list-style: initial;
	margin-left: 1.6rem;
	font-size: 1.6rem;
}

.li {
	list-style: initial;
}

.tableWrapper {
	width: 100%;
	overflow-x: scroll;
}

.rich-text-wrapper {
	width: 100%;
	margin-inline: auto;

	h1,
	h2,
	h3,
	h4,
	h5,
	h6,
	p {
		color: inherit !important;
	}

	p {
		font-style: normal;
		font-weight: 400;
		font-size: 1.4rem;
		line-height: 150%;
		margin-block-end: 1.6rem;

		&:empty {
			display: none;
		}
	}

	a {
		color: $blue-2;
		text-decoration: none;
		border-radius: $radius-2;
		cursor: pointer;
		transition: all 200ms ease-out;

		&:focus-visible {
			color: $blue-3;
			box-shadow: 0 0 0 2px $blue-1;
		}

		&:active {
			background-color: rgba(0, 0, 0, 0.08);
			color: $blue-2;
		}

		@media (hover: hover) {
			&:hover:not(:active):not(:focus-visible):not(.full-opacity) {
				background-color: rgba(0, 0, 0, 0.04);
				color: $blue-1;
				opacity: 0.6;
			}
		}
	}

	table, th, td {
		border: 1px solid;
	}

	th, td {
		padding: 0.4rem;
		p {
			margin: 0;
		}
	}

	th {
		text-align: center;
	}

	&.Body p {
		font-weight: 300;
		font-size: 1.6rem;
		line-height: 2.4rem;
	}

	&.Caption-Small p {
		font-weight: 500;
		font-size: 1.2rem;
	}

	&.Caption-Large p {
		@extend .Caption-Small;
		font-size: 1.6rem;
	}

	&.font-size-override {
		a,
		p {
			font-size: inherit;
			line-height: inherit;
		}
	}

	&.remove-heading-margin {
		h1,
		h2,
		h3,
		h4,
		h5,
		h6 {
			margin-block-end: 0;
			line-height: 1;

			& + .painted {
				margin-block-start: 1.6rem;

				@media screen and (max-width: $media-md-max) {
					margin-block-start: 0.8rem;
				}
			}
		}
	}

	&.hide {
		@media screen and (max-width: $media-md-max) {
			display: none;
		}
	}

	&.skinny-banner {
		height: 25.6rem;
		display: flex;
		flex-direction: column;
		justify-content: center;
	}

	.painted {
		display: flex;
		flex-flow: row nowrap;
		align-items: center;

		.inline-nodes {
			margin: 0 !important;
			line-height: 3rem !important;
			position: relative;
			top: -2px;
		}

		.cut-paper {
			position: relative;
			margin-inline: 0.4rem;
		}

		p {
			margin-block-end: 0;
		}
	}
}
.hyperlink{
	strong{
		font-weight: 400;
	}
}
