@import '__importable.scss';
.divider {
	height: 20px;
	width: 1px;
	background-color: $gray-3;

	&--horizontal {
		min-height: 1px;
		height: 1px;
		width: 100%;
		background-color: $gray-3;
	}
}
