@import '__importable.scss';
.container {
	display: none;

	@media screen and (min-width: $media-md) {
		display: unset;
		margin-left: 2.4rem;

		a {
			display: flex;
			position: relative;
			flex-direction: column;
			width: 25.4rem;
			height: 45.5rem;
			padding: 1.6rem 0.8rem;
			border-radius: 0.8rem;
			align-items: flex-start;
			overflow: hidden;
		}

		.heading {
			display: flex;
			flex-direction: column;
			margin: 0 auto;
			padding: 0;
			width: 100%;
			gap: 0.2rem;
			margin-top: 1rem;
			margin-left: 2rem;
			.title{
				margin-bottom: 0 !important;
			}
		}

		img {
			position: absolute;
			top: 0;
			left: 0;
			object-fit: cover;
			z-index: $zindex-behind;
		}

		button {
			width: 100%;
			margin-top: auto;
			position: relative;
			bottom: auto;
			right: auto;
		}
	}
}
