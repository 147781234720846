@import '__importable.scss';
.container {
	display: flex;
	flex-direction: column;
	gap: 0.8rem;
	margin-bottom: 9.6rem;

	ul {
		flex: 1;

		li {
			position: relative;

			a {
				img {
					width: 100%;
					object-fit: cover;
					border-radius: $radius-2;
					max-height: 19.2rem;
				}

				button {
					position: absolute;
					bottom: 1.6rem;
					right: 1.6rem;
					z-index: $zindex-1;
				}
			}
		}
	}

	.images-menu-lozenge {
		position: absolute;
		bottom: 1.6rem;
		left: 1.6rem;
		// z-index: $zindex-1;
	}

	@media screen and (min-width: $media-md) {
		display: flex;
		flex-direction: row;
		gap: 1.6rem;
		max-height: 32rem;
		margin-bottom: 0;

		ul {
			flex: 1;
			margin: 0;
			li {
				padding: 0;
				position: relative;
				border-color: $white;
				overflow: hidden;

				a {
					overflow: hidden;
					display: flex;
					img {
						position: relative;
						max-height: 25.6rem;
					}
				}

				@media (hover: hover) {
					&:hover {
						opacity: 0.6;
					}

					&:active {
						opacity: 1;
						a button {
							&:not([disabled]) {
								box-shadow: none;
							}
						}
					}
				}
			}
		}
	}
}
