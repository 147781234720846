@import '__importable.scss';
.overlay {
	background: rgba(0 0 0 / 0.25);
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	display: grid;
	place-items: center;
}

.content {
	position: fixed;
	height: 100vh;
	width: 100vw;
	max-width: 85rem;
	min-width: 35rem;
	z-index: $zindex-10;
	border-radius: $radius-6;
	top: 50%;
	left: 50%;
	translate: -50% -50%;
	margin-top: 10%;

	.loading {
		margin-top: 1.6rem;
	}

	:global {
		.glasseson.webapp {
			.go-close {
				display: none; // use the parent modal's close button
			}
		}
	}
}

.message {
	background-color: $white;
	height: fit-content;
	width: 90%;
	border-radius: $radius-6;
	margin: auto;
	padding: 4rem;
}

.close {
	position: absolute;
	top: 0;
	right: 1rem;
	border-radius: $radius-round;
	background-color: $white;
	z-index: $zindex-2;

	&:focus-visible {
		box-shadow: 0 0 0 2px $pink-2;
	}

	@media screen and (min-width: $media-md) {
		right: 0;
	}
}
