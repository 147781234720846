@import '__importable.scss';
.default {
	font-weight: 500;
	font-style: normal;
	overflow-wrap: break-word;
	word-wrap: break-word;
	margin: 0;
	font-family: var(--platform);

	* {
		font-family: inherit;
	}
}

.defaultMargin {
	margin-bottom: 0.8rem;
}

.h1 {
	font-size: clamp(3.6rem, 4vw, 4rem);
	line-height: clamp(3.4rem, 4vw, 4.8rem);
	letter-spacing: 0.004em;

	&--noClamp {
		font-size: 4rem;
		font-weight: 400;
		line-height: normal;
		letter-spacing: 0.016rem;
	}
}

.h2 {
	font-size: clamp(2.8rem, 4vw, 3.6rem);
	line-height: clamp(3rem, 4vw, 3.3rem);
	letter-spacing: 0.0036em;

	&--noClamp {
		font-size: 3.6rem;
		line-height: normal;
		letter-spacing: 0.013rem;
	}
}

.h3 {
	font-size: clamp(2.4rem, 4vw, 3.2rem);
	line-height: clamp(2.6rem, 4vw, 3.2rem);
	letter-spacing: 0.0032em;

	&--noClamp {
		font-size: 3.2rem;
		font-weight: 500;
		line-height: normal;
		letter-spacing: 0.0102rem;
	}
}

.h4 {
	font-size: clamp(1.8rem, 4vw, 2.8rem);
	line-height: clamp(2.2rem, 4vw, 2.8rem);
	letter-spacing: 0.0028em;
	&--noClamp {
		font-size: 2.8rem;
		font-weight: 500;
		line-height: normal;
		letter-spacing: 0.0078rem;
	}
}

.h5 {
	font-size: clamp(2rem, 4vw, 2.4rem);
	line-height: clamp(2rem, 4vw, 2.4rem);
	letter-spacing: 0.0024em;
	&--noClamp {
		font-size: 2.4rem;
		font-weight: 400;
		line-height: normal;
		letter-spacing: 0.0058rem;
	}
}

.h6 {
	font-size: clamp(1.6rem, 4vw, 2rem);
	line-height: clamp(2rem, 4vw, 2.4rem);
	letter-spacing: 0.002em;
	&--noClamp {
		font-size: 2rem;
		line-height: normal;
	}
}
