@import '__importable.scss';
.container {
	@include tooltipContent;
	svg {
		polygon {
			fill: white;
		}
	}
}

.message {
	@include tooltipMessage;
	text-align: center;
}
