@import '__importable.scss';
.container {
	width: 100%;
	background: $gray-0;
	border-radius: $radius-6;

	&[data-show-border='true'] {
		padding: 1.6rem;
		border: 1px solid $gray-2;
	}
}

.description,
.detail {
	display: block;
	text-align: center;
	width: 100%;
}

.description {
	margin-bottom: 0.8rem;
}

.tracker {
	height: 2.1rem;
}

.progress {
	position: relative;
	flex-basis: calc(100% - 10.7rem);
	height: 0.4rem;
	margin: 0 0.8rem;
	padding: 0.1rem 0;
	border-radius: $radius-1;
	background: $gray-2;
	background-clip: content-box;

	&-dot {
		position: absolute;
		inset: 0;
		width: 0.4rem;
		height: 0.4rem;
		border-radius: $radius-1;
		background: $blue-1;
	}

	&-bar {
		position: absolute;
		top: 0;
		left: 0;
		width: 0.4rem;
		height: 0.4rem;
		border-radius: $radius-1;
		background: $blue-1;
		transition: width 250ms ease-out;
	}
}