@import '__importable.scss';
.nav-icon {
	@include navbarUserActionIcon;

	@media screen and (min-width: $media-sm) {
		&:hover {
			svg {
				transform: scale(1.1);
			}
		}
	}
}
