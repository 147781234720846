@import '__importable.scss';
.container {
	padding: 0;
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	max-width: $max-width;
	width: 100%;
	height: 100%;
	overflow: hidden;
}

.navIcon {
	@include navbarUserActionIcon;
}

.navButton {
	height: 100%;
	width: 100%;
}