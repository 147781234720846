@import '__importable.scss';
//NOTE: column sizes are desktop-based; mobile will be 1fr less
.container {
	display: grid;

	&--1col {
		grid-template-columns: 1fr;
	}

	&--2col {
		grid-template-columns: 1fr;
	}

	&--3col {
		grid-template-columns: 1fr 1fr;
	}

	&--4col {
		grid-template-columns: 1fr 1fr 1fr;
	}

	&--center {
		place-items: center;
	}

	@media screen and (min-width: $media-lg) {
		&--1col {
			grid-template-columns: 1fr;
		}

		&--2col {
			grid-template-columns: 1fr 1fr;
		}

		&--3col {
			grid-template-columns: 1fr 1fr 1fr;
		}

		&--4col {
			grid-template-columns: 1fr 1fr 1fr 1fr;
		}
	}
}
