@import '__importable.scss';
.container {
	&Hoverable {
		&:not([aria-disabled='true']) {
			border-radius: $radius-4;
		}

		&--blue {
			@include hoverable;
		}

		&--gray {
			@include hoverable($gray-3);
		}
	}

	&[aria-disabled='true'] {
		padding: 0.8rem;
		gap: 0.8rem;
		border-radius: $radius-4;
		border: 1px solid $gray-2;

		background-color: $white;
	}

	display: flex;
	align-items: center;
	width: 100%;

	img {
		height: 24px;
		width: 24px;
		border-radius: $radius-round;
		overflow: hidden;
	}

	&:active {
		.checkbox:not([data-disabled]) {
			border-color: $gray-4;
			color: rgba(0, 0, 0, 0.3);
		}

		.checkbox[data-state='checked']:not([data-disabled]) {
			background-color: $blue-shade;
			border-color: $blue-shade;
			color: $white;
		}
	}

	@media (hover: hover) {
		&:hover:not(:active):not(:focus-visible) {
			.checkbox:not([data-state='checked']):not([data-disabled]) {
				color: rgba(0, 0, 0, 0.1);
			}

			.checkbox[data-state='checked']:not([data-disabled]) {
				background-color: $blue-tint;
				border-color: $blue-tint;
			}
		}
	}
}

.checkbox {
	height: 16px;
	width: 16px;

	margin: 8px;

	border: 2px solid $gray-3;
	border-radius: $radius-1;

	display: flex;
	align-items: center;
	justify-content: center;
	flex-shrink: 0;

	color: $white;
	background-color: $white;

	&[data-state='checked'] {
		background-color: $blue;
		border-color: $blue;
	}

	&[data-disabled][data-state='checked'] {
		background-color: $gray-3;
		border-color: $gray-3;
	}

	svg {
		width: 9px;
		margin-left: 1px;
	}
}

.loading {
	margin: 2px 5.5px 0;
	border-width: 2px;
}
