@import '__importable.scss';
.container {
	display: flex;
	flex-direction: column;
	gap: 1.6rem;
}

.option {
	@include hoverable;

	display: flex;
	align-items: center;

	img {
		height: 24px;
		width: 24px;
		border-radius: 50%;
		overflow: hidden;
	}

	&:active:not([disabled]) {
		.radioOuter {
			background-color: rgba(0, 0, 0, 0.08);

			.radioInner {
				border-color: $gray-4;
			}

			.radioDot {
				background-color: rgba(0, 0, 0, 0.15);
			}
		}

		.radioOuter[data-state='checked'] {
			.radioInner {
				border-color: $blue-shade;
			}
			.radioDot {
				background-color: $blue-shade;
			}
		}
	}

	@media (hover: hover) {
		&:hover:not([disabled]):not(:active):not(:focus-visible) {
			border-color: $gray-3;
			box-shadow: 1px -1px 0px 1px $gray-3 inset;

			.radioOuter {
				background-color: rgba(0, 0, 0, 0.04);

				.radioDot {
					background-color: rgba(0, 0, 0, 0.1);
				}
			}

			.radioOuter[data-state='checked'] {
				.radioInner {
					border-color: $blue-tint;
				}
				.radioDot {
					background-color: $blue-tint;
				}
			}
		}
	}
}

.radioOuter {
	height: 3.2rem;
	width: 3.2rem;
	min-width: 3.2rem;
	flex-shrink: 0;
	border-radius: $radius-round;
	background-color: $white;
	display: flex;
	align-items: center;
	justify-content: center;

	&[data-state='checked'] {
		.radioInner {
			border-color: $gray-4;
		}

		.radioDot {
			background-color: $blue;
			height: 1rem;
			width: 1rem;
		}
	}
}

.radioInner {
	height: 1.6rem;
	width: 1.6rem;

	border: 2px solid $gray-3;
	border-radius: 50%;

	background-color: $white;

	display: flex;
	align-items: center;
	justify-content: center;
}

.radioDot {
	height: 0.8rem;
	width: 0.8rem;
	background-color: $white;
	border-radius: 50%;
}

.disabled {
	pointer-events: none;
}
