@import '__importable.scss';
.container {
	padding: 1.6rem;
	border-radius: $radius-6;
}

.content-list-item {
	position: relative;
	padding-left: 2.4rem;

	&:not(:last-child) {
		margin-bottom: 1.6rem;
	}

	display: flex;
	align-items: center;
	justify-content: space-between;

	&.with-icon{
		justify-content: normal;
		gap: 0.8rem;
		padding-left: 0;
		padding: 0.8rem;
	}

	&.checkmark::before {
		content: '';
		position: absolute;
		top: 3px;
		left: 0;
		transform: rotate(45deg);
		height: 12px;
		width: 6px;
		border-bottom: 2px solid $green-3;
		border-right: 2px solid $green-3;
	}

	&.crossmark::before {
		content: '\274c';
		transform: rotate(0);
		position: absolute;
		top: 4px;
		left: -3px;
	}
}

.header{
	padding-bottom: 0.4rem;
}

.cart-icon {
	width: 10%;

	@media screen and (min-width: $media-xsm) {
		width: 2.4rem;
	}
}