@import '__importable.scss';
.container {

	.title {
		padding-block-end: 1.6rem;
	}
}

.hits {
	padding: 0;
	display: grid;
	grid-template-columns: 1fr 1fr;
	gap: 0.8rem;
	margin-bottom: 1.6rem;

	>* {
		min-width: 0;
	}
}

.hidden {
	display: none;
}

.resultsGrid {
	margin: 0 auto 3.2rem;
	width: 100%;
	max-width: 118.4rem;

	grid-template-columns: 1fr 1fr;

	@media screen and (min-width: $media-md) {
		grid-template-columns: 1fr 1fr 1fr;
	}

	@media screen and (min-width: $media-lg) {
		grid-template-columns: 1fr 1fr 1fr 1fr;
	}
}