@import '__importable.scss';
.default {
	font-weight: 400;
    font-style: normal;
	font-size: 1.4rem;
    overflow-wrap: break-word;
    word-wrap: break-word;
	line-height: 150%;
	font-family: var(--poppins);

	a {
		font-weight: 500;
		font-family: var(--poppins);
		color: $blue;
		text-decoration: none;
		cursor: pointer;
		transition: all 200ms ease-out;

		@media screen and (hover: hover){
			&:hover {
				opacity: 0.6;
				color: $blue-tint;
			}
			&:active {
				color: $blue;
			}
		}
	}
}

.large {
	font-size: 2.4rem;
}

.small {
	font-size: 1.2rem;
	font-style: italic;
}
