@import '__importable.scss';
.container {
  background-color: $lightblue-1;
  border-radius: 8px;
  color: $blue-3;
  display: flex;
  font-size: 1rem !important;
  gap: 0.5rem;
  padding: 8px 16px;
  min-height: 37px;
  width: 100%;
  align-items: center;
  min-height: inherit;
}

.info {
  overflow: visible;

	path, circle {
		stroke-width: 2px;
	}
}

.paragraph {
  align-self: center;
}

.mobileMargin {
  margin: 0 8px;
  width: calc(100% - 16px);

    @media screen and (min-width: $media-xl) {
      margin: 0;
      width: 100%;
    }
}

.bottomMargin {
  margin-bottom: 2.4rem;
}

.extraMargin {
  margin: 2.4rem 8px 0 8px;
  width: calc(100% - 16px);

    @media screen and (min-width: $media-xl) {
      margin: 2.4rem 32px 0 32px;
      width: calc(100% - 64px);
    }

    @media screen and (min-width: $media-xxl) {
      margin: 2.4rem 6.4rem 0 6.4rem;
      width: calc(100% - (6.4rem * 2));
    }

    @media screen and (min-width: em-calc(1920px)) {
      margin: 2.4rem 12.8rem 0 12.8rem;
      width: calc(100% - (12.8rem * 2));
    }
}
