@import '__importable.scss';

.tags {
	position: absolute !important;
	top: -9.5px;
	left: 0;
	padding: 0 1.6rem;
	overflow-x: scroll;
	width: -webkit-fill-available;
	width: inherit;

	@include noScrollBar;

	display: flex;
	align-items: center;
	gap: 0.4rem;

	&Horizontal {
		position: static;
		padding: unset;
		padding-bottom: 0.8rem;
		top: 0.5rem;
		left: 0.8rem;
	}

	&Compact {
		padding: 0 0.8rem;
	}

	&LineItem{
		top: 0.5rem;
	}

	&Rigth{
		right: -1rem;
		left: auto;
		top: 1.1rem;
	}

	a {
		white-space: nowrap;

		img {
			height: 1.2rem;
			object-fit: contain;
		}
	}
	[class*='Lozenge'] {
		white-space: nowrap;
	}
}