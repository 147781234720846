@import '__importable.scss';
.link {
	font-weight: 500;
	font-family: var(--poppins);
	border-radius: $radius-2;
	color: $blue-2;
	text-decoration: none;
	cursor: pointer;
	transition: all 200ms ease-out;
	padding: 0 8px;
	font-size: 1.8rem;
	line-height: 2.7rem;

	&--small {
		padding: 0 4px;
		font-size: 1.4rem;
		line-height: 2.1rem;
	}

	&--medium {
		padding: 0 8px;
		line-height: normal;
		height: 40px;
	}

	
	&--xsmall {
		padding: 0 8px;
		font-size: 1.2rem;
		line-height: normal;
	}

	&:focus-visible {
		outline: 0;
	}

	&:disabled,
	[disabled] {
		background-color: $gray-2;
		color: $gray-3;
		cursor: not-allowed;
	}

	&:not([disabled]) {
		&:focus-visible {
			color: $blue-3;
			box-shadow: 0 0 0 2px $blue-1;
		}

		&:active {
			background-color: rgba(0, 0, 0, 0.08);
			color: $blue-2;
		}

		@media (hover: hover) {
			&:hover:not(:active):not(:focus-visible):not(.full-opacity) {
				background-color: rgba(0, 0, 0, 0.04);
				color: $blue-1;
				opacity: 0.6;
			}
		}
	}

	span {
		font-family: inherit;
		
		&[data-chevron] {
			font-family: inherit;
			display: flex;
			align-items: center;
			gap: 0.8rem;
		}
	}
}
