@import '__importable.scss';
.subtotal-container {
	display: flex;
	flex-flow: row nowrap;
	align-items: center;
	justify-content: space-between;
	line-height: 2.1rem;
	margin-bottom: 0.8rem;
	gap: 0.8rem;

	.subtotal {
		margin-left: 0.5ch;
		margin-bottom: 0;

		@media (width < $media-sm) {
			margin-left: 0 !important;
			font-size: 1.4rem !important;
			line-height: 2.1rem !important;
		}
	}
}

// Smaller screens with less height on mobile
@media screen and (max-width: $media-xsm) and (min-height: $media-sm) {
	.subtotal-container {
		margin-bottom: 0.8rem;
	}
}

.loading {
	// Trash Icon is 32x32, so adding 5.5px padding keeps right spacing consistent during load
	position: relative;
	top: -2rem;
	margin: 0 5.5px;
	border-width: 2px;
}
