@import '__importable.scss';
.mobileNavButton {
	display: flex;
	align-items: center;
	height: 100%;
	width: 3.2rem;
	padding: 0;

	&:focus {
		outline: none;
	}

	@media screen and (min-width: $media-md) {
		display: none;
	}

	svg {
		&:first-of-type {
			display: flex;
		}

		&:last-of-type {
			display: none;
		}
	}

	&[data-is-mobile-nav-open='true'] {
		svg {
			&:first-of-type {
				display: none;
			}

			&:last-of-type {
				display: flex;
			}
		}
	}
}

.logo {
	margin-right: 2rem;

	> h1,
	> div {
		display: flex;
		height: 100%;
		margin: 0;
	}

	a {
		display: flex;
		align-items: center;
		margin: 0;

		svg {
			width: 6.4rem;
			max-width: inherit;

			&:first-of-type {
				display: flex;
			}

			&:last-of-type {
				display: none;
			}
		}

		&:hover {
			opacity: 0.6;
		}

		@media screen and (min-width: $media-md) {
			svg {
				&:first-of-type {
					display: none;
				}

				&:last-of-type {
					display: flex;
				}
			}
		}
	}
}
