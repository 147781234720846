@import '__importable.scss';
.icon {
	cursor: pointer;

	outline: 0;
	* {
		transition: 100ms ease-in;
	}

	@media screen and (min-width: $media-sm) {
		&:hover,
		&:focus-visible {
			*:nth-child(n + 3):nth-child(-n + 4) {
				transform: translate(-2px, 0.5px) rotate(-10deg);
			}
		}

		&:active,
		&:focus {
			*:nth-child(n + 3):nth-child(-n + 4) {
				transform: unset;
			}
		}
	}
}

.button {
	@include navbarUserActionIcon;
}

.loading {
	// Trash Icon is 32x32, so adding 5.5px padding keeps right spacing consistent during load
	margin: 0 5.5px 3rem;
	border-width: 2px;
}
