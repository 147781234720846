@import '__importable.scss';
.content {
	flex-wrap: wrap;
	align-items: center;
	align-content: center;
	align-self: stretch;
	gap: 0.8rem;
}

.withDivider {
	border-bottom: 1px solid $gray-3;
	padding-bottom: 0.8rem;
}

.copy {
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	flex: 1 0 0;
	min-width: 36rem;
	gap: 0.4rem;
}

.subtitleGroup {
	svg {
		height: 2.4rem;
		width: 2.4rem;
	}	
}