@import '__importable.scss';
.input-container {
	width: 100%;
	height: 6rem;
	min-height: 6rem;
	position: sticky;
	top: 0;
	background-color: $gray-0;
	padding: 0 1.6rem;
	border-bottom: 1px solid $gray-2;
	z-index: $zindex-2;
}

.heading {
	display: flex;
	gap: 0.8rem;
}

.suggestions {
	overflow-y: auto;
	@include noScrollBar;

	&[data-has-results="false"] {
		display: none;
	}

	&__results {
		width: 100%;
		padding: 1.6rem;
		gap: 1.6rem;
	}

	&__button {
		width: calc(100% - 1.7rem);
		position: fixed;
		bottom: 0;
		padding: 0.8rem;
		background: $white;
		border-top: 1px solid $gray-2;
		z-index: $zindex-1;

		button {
			margin: 1.6rem 3.2rem 1.6rem 1.6rem;
			text-align: center;
		}
	}

	[class*='Hits_container'] {
		padding-bottom: 6.4rem; //Spacing for Fixed Button
	}

	@media screen and (width >= $media-md) {
		&__results {
			padding: 2.4rem 2.4rem 0;
		}
	}
}