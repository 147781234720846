@import '__importable.scss';
@import '@styles/colors';
@import '@styles/variables';

.icon {
	display: flex;
	align-items: center;
	justify-content: center;
	border-radius: $radius-round;
	border: 2px solid transparent;
	padding: 0;
}
