@import '__importable.scss';
.container {
	display: none;

	@media screen and (min-width: $media-md) {
		display: unset;
		margin-left: 2.4rem;

		a {
			display: flex;
			position: relative;
			flex-direction: column;
			width: 25.4rem;
			height: 45.5rem;
			padding: 1.6rem 0.8rem;
			border-radius: 0.8rem;
			align-items: center;
			overflow: hidden;
		}

		.heading {
			font-family: var(--platform);
			display: flex;
			align-items: center;
			justify-content: center;
			margin: 0 auto;
			width: 23.2rem;
			height: 5.4rem;
			margin-top: -0.5rem;
			font-size: 3.2rem;
			font-weight: 500;
			line-height: 3.84rem;
		}

		img {
			position: absolute;
			top: 0;
			left: 0;
			object-fit: cover;
			z-index: $zindex-behind;
		}

		button {
			width: 80%;
			margin-top: 2rem;
			position: relative;
			bottom: auto;
			right: auto;
			margin-inline: 2rem;
			span {
				font-size: 1.5rem !important;
				font-family: var(--platform);
			}
		}
	}
}
