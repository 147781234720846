@import '__importable.scss';
.default {
	font-weight: 500;
    font-style: normal;
	font-size: 1.8rem;
	line-height: 2.7rem;
    overflow-wrap: break-word;
    word-wrap: break-word;
	font-family: var(--poppins);

	&.xsmall {
		font-size: 1rem;
		line-height: 150%;
	}

	&.small {
		font-size: 1.4rem;
		line-height: 150%;
	}
}