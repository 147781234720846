@import '__importable.scss';
.main {
	height: 100%;
	min-height: 100vh;
	padding-top: $headerHeight;
	width: 100%;

	.content {
		max-width: $max-width;
		margin-left:auto;
		margin-right:auto;
	}

	&[data-page='cart'] {
		padding-top: 9.4rem;
	}

	&.account {
		.content {
			height: inherit;
		}

		&.orders-id-page {
			height: auto;
		}
	}
}

.gray0 {
	background-color: $gray-0;
}

.gray1 {
	background-color: $gray-1;
}
.gray2 {
	background-color: $gray-2;
}

.noHeader {
	padding-top: $announcement-bar-height;
}
