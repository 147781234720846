@import '__importable.scss';
.container {
	position: relative;
	width: fit-content;

	.cutpaper {
		position: relative;
		z-index: 2;
		background-color: white;
	}
}
