@import '__importable.scss';
.container {
	border-radius: $radius-4;
	background-color: $gray-1 !important;
}

.checkbox {
	padding: 0.8rem 1.6rem 0.8rem 0.8rem;
	align-items: flex-start !important;

	> button {
		margin-top: 4px;
	}

	&:has(+ button[data-state='checked']) {
		.price {
			color: black;
		}
	}
}

.pink {
	color: $pink-shade;
}

.blue {
	color: $blue;
}

.price {
	color: $gray-4;
}

.checkedContainer {
	background-color: $gray-0 !important;
}

.disabledCheckedContainer{
	pointer-events: none;

	&:hover:not(:active):not(:focus-visible) {
		border-color: $gray-2;
		box-shadow: none;
		> * {
			transform: none;
		}
	}

	button {
		&[disabled]{
			&[data-state='checked'] {
				background: $gray-3;
				border-color: $gray-3;
			}
		}

		&:not([data-state='checked']) {
			pointer-events: initial;
		}
	}

	&:hover {
		// Overrides of mixin @hoverable
		@at-root .disabledCheckedContainer {
			@media (hover: hover) {

				&:hover,
				&:active,
				&:focus-visible {
					border-color: $gray-2;
					box-shadow: none;
					background-color: $gray-0;
					> * {
						transform: none;
					}
				}
			}
		}
	}
}

.pairCarePopover {
	border-radius: 0.4rem;
	padding: 1.6rem;
	opacity: 1;
	background: $white;
	width: 24.4rem !important;
	color: $black;
}

.hasChildren {
	border-radius: 0.8rem 0.8rem 0 0 !important;
}

.childrenContainer {
	padding: 1.6rem;
}
