@import '__importable.scss';
.container {
	flex-shrink: 0;
	position: relative;
	display: flex;
	justify-content: center;
	align-items: center;
}

.hoverable {
	@include hoverableNoPadding($gray-3);
}

.square {
	width: 4rem;
	height: 4rem;
	border-radius: 0.4rem;
}

.circle {
	width: 4rem;
	height: 4rem;
	border-radius: 50%;
}

.wide-square {
	width: 5.6rem;
	height: 4rem;
	border-radius: 0.4rem;
}

.floating {
	width: 4rem;
	height: 4rem;
	border-radius: 50%;

	&:not(:hover):not(:active) {
		background-color: transparent;
		border-color: transparent;
	}
}

.fill {
	height: 4rem;
	flex-grow: 1;
}
