@import '__importable.scss';
.default {
	font-style: normal;
	font-weight: 500;
	font-size: 1.4rem;
	line-height: normal;
	letter-spacing: 0.028rem;
	font-family: var(--platform);
	
	&.small {
		font-size: 1.2rem;
		letter-spacing: 0.024rem;
	}
}