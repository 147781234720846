@import '__importable.scss';
.message {
	@include tooltipMessage;
}

.content {
	@include tooltipContent;
	z-index: $zindex-max;
}

.button {
	display: flex;
	flex-shrink: 0;
}

.icon {
	flex-shrink: 0;
	@media (hover: hover) {
		&:hover {
			circle {
				stroke: $gray-4;
			}
			path {
				fill: $gray-4;
			}
		}
	}

	&:focus {
		outline: none;
	}
}
